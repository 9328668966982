<template>
  <div>
    <b-card>
      <div class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a>
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t("products.title") }}
          </h3>
        </div>
        <div class="d-flex align-items-center flex-row-reverse">
          <b-button
            v-if="canCreate"
            v-b-modal.modal-create-product
            class="float-right mr-1"
            show-add
            variant="outline-primary"
          >
            {{ $t("products.create") }}
          </b-button>
          <widget-actions
            :show-add="false"
            :isSearchNeeded="false"
            item-type="services"
            :showMaximize="false"
            :show-filter="showfilter"
            @toggleFilters="toggleFilters"
            class="mr-2"
          />
        </div>
      </div>
      <template>
        <div
          v-if="areFiltersVisible"
          class="flex-column flex-md-row justify-content-center filters-container mb-2 px-50 pt-1"
        >
          <!-- Classifiers -->
          <div
            class="d-flex flex-column flex-md-row filters-container mb-2 px-50 pt-1"
          >
            <b-form-group
              :label="$t('filterType')"
              label-for="classifier-filter"
              class="mr-1 w-100"
            >
              <v-select
                v-model="selectedClassifiersForFilter"
                name="classifier-filter"
                clearable
                label="name"
                :reduce="(a) => a"
                :options="finalOptions"
                @input="setSeletedFilter"
              />
            </b-form-group>
            <!-- End Classifiers -->

            <!-- Custom -->
            <b-form-group
              v-if="nextFilter != null"
              label="Value"
              label-for="custom-filter"
              class="mr-1 w-100"
            >
              <v-select
                v-if="nextFilter[0] != null"
                v-model="selectedCustomFieldsForFilter"
                name="custom-filter"
                clearable
                label="name"
                :reduce="(nextFilter) => nextFilter"
                :options="nextFilter"
                @input="setSeletedFinalFilter"
              />

              <b-form-input
                v-if="nextFilter.type === 2"
                v-model="customFieldToSend[nextFilter.key]"
                placeholder="Start typing..."
                class="w-100"
                @keyup="handleChanger"
                @input="
                  setSeletedFinalFilter({
                    text: $event,
                    type: 2,
                    key: nextFilter.key,
                  })
                "
              />
            </b-form-group>
            <!-- End Custom -->
          </div>
        </div>
      </template>
      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="canView && items && items.length > 0" class="cards-container">
        <div>
          <!-- Items Loop -->
          <b-card
            v-for="(item, index) in items"
            :key="index"
            no-body
            class="float-left mx-1 mb-2 p-0 card-standard"
          >
            <b-link
              :to="
                canView ? { name: 'product', params: { id: item.key } } : null
              "
            >
              <!-- Image -->
              <b-card-body class="pb-2">
                <div class="item-img text-center">
                  <b-img
                    :alt="`${item.name}`"
                    fluid
                    :src="getImageSrc(item.bgURL) || productPlaceholder"
                    class="card-image-medium"
                  />
                </div>
                <!-- Product Details -->
                <b-card-text v-if="item.unitPrice && item.unitPrice > 0">
                  <h5 class="font-weight-bold precio">
                    {{ item.unitPrice }} {{ item.currency.ISO }}
                  </h5>
                </b-card-text>
                <div v-else class="min-height-1 mt-1" />

                <h4 class="text-ellipsis-lines-2 titulo">
                  {{ item.name }}
                </h4>
                <span
                  v-if="item.headline"
                  class="min-height-4 html-text-ellipsis-2 text-secondary"
                  v-html="item.headline"
                />
                <!-- <span
                  v-if="item.description"
                  class="min-height-3 html-text-ellipsis-2 text-secondary"
                  v-html="item.description"
                /> -->
                <div v-else class="min-height-3" />
              </b-card-body>
            </b-link>
          </b-card>
        <div
          v-if="items.length < itemsData.meta.total"
          class="w-100 float-left"
        >
          <b-button
            v-if="!isLoadingNextPage"
            class="center-x my-3"
            variant="outline-primary"
            @click="handleLoadOfNewItems"
          >
            {{ $t("action.load-more") }}
          </b-button>
          <div v-else class="w-100">
            <b-spinner
              class="center-x my-3"
              small
              variant="primary"
              type="grow"
              label="Loading..."
            />
          </div>
        </div>
        </div>
      </div>
      <b-row v-else-if="!canView" class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="productsPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('no-permisions.message') }}
          </p>
        </b-col>
      </b-row>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="productsPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: "items" }) }}
          </p>
        </b-col>
      </b-row>
    </b-card>

    <!-- Create Product Modal -->
    <ManageProductModal
      ref="manageProductModal"
      :product="currentProduct"
    />
  </div>
</template>

<script>
import ProductsPlaceholder from "@/assets/images/placeholders/light/projects.svg";
import ProductPlaceholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import { getImageResource } from "@/@core/utils/image-utils";
import { quillEditor } from "vue-quill-editor";
import Service from "@/config/service-identifiers";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import FileTypes from "@/@core/constants/FileTypes";
import { checkPermissions } from "@/@core/utils/roles-utils";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import PlacesMapSelect from '@core/components/places-map-input/PlacesMapSelect.vue';
import ManageProductModal from './ProductManageModal.vue';

export default {
  name: "ProductsList",
  components: {
    quillEditor,
    WidgetActions,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    PlacesMapSelect,
    ManageProductModal,
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      productInput: {
        currencyCode: "EUR",
        locations: [],
      },
      formError: null,
      itemImage: null,
      imageSrc: null,
      currencyCode: {},
      currencyCo: [],
      showfilter: false,
      areFiltersVisible: false,
      nextFilter: null,
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      currentProduct: null
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});

    return {
      getValidationState,
    };
  },
  computed: {
    // Load data from store
    FileTypes() {
      return FileTypes;
    },
    itemsData() {
      return this.$store.getters.products;
    },
    items() {
      return this.itemsData.unpaginated;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    productPlaceholder() {
      return ProductPlaceholder;
    },
    productsPlaceholder() {
      return ProductsPlaceholder;
    },
    classifiers() {
      if (this.$store.getters.classifiers.services) {
        return this.$store.getters.classifiers.services.unpaginated;
      }
      return [];
    },
    sortedCommunityClassifiers() {
      const newObject = {};
      for (const item of this.classifiers) {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ].push(item);
        }
      }
      const keys = Object.keys(newObject);
      const finalKeys = [];
      for (const row of keys) {
        finalKeys.push({
          key: row,
          name: row,
          type: 1,
        });
      }
      return [newObject, finalKeys];
    },
    finalOptions() {
      return [].concat(
        this.sortedCommunityClassifiers[1],
        this.customFieldsOptions
      );
    },
    canCreate() {
      return checkPermissions(
        "create",
        "product",
        this.loggedMemberRoles,
        this.collective
      );
    },
    canView() {
      return checkPermissions(
        "view",
        "product",
        this.loggedMemberRoles,
        this.collective
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    appId() {
      return 3;
    },
    app() {
      return this.$store.getters.apps.apps[this.appId];
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName
      );
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    this.updateBreadcrumbs();
    await this.fetchData(true);
    await this.fetchCurrencies();
    await this.fetchClassifiers();
    this.isLoading = false;
  },
  methods: {
    updateBreadcrumbs() {
      this.$store.commit("app/SET_BREADCRUMBS", null);
    },
    // Filter Methods
    canFilter() {
      if (this.classifiers.length > 0) {
        this.showfilter = true;
      } else {
        this.showfilter = false;
      }
    },
    async fetchClassifiers() {
      this.isLoading = true;
      let res = await this.$store.dispatch("getItems", {
        itemType: "backoffice/classifiers",
        storedKey: "services",
        customName: "classifiers",
        page: 1,
        requestConfig: {
          modelType: "services",
          count: 1000,
        },
      });
      this.canFilter();
    },
    toggleFilters() {
      this.areFiltersVisible = !this.areFiltersVisible;
      if (!this.areFiltersVisible) {
        this.customFieldToSend = {};
        this.finalSelectClassifier = [];
        (this.selectedCustomFieldsForFilter = null), (this.nextFilter = null);
        (this.total = 0),
          (this.filtersIndex = 0),
          (this.filters = []),
          (this.parentClassifiers = []),
          (this.selectedClassifiersForFilter = []);
        this.customFieldsInputs = [];
        this.index = 0;
        this.fetchData(true);
      }
    },
    async setSeletedFinalFilter(selected) {
      if (selected.type === 2) {
        this.customFieldToSend[selected.key] = selected.text;
      } else if (selected) {
        this.finalSelectClassifier = selected.key;
      } else {
        this.finalSelectClassifier = [];
      }
      this.handleChanger();
    },
    async setSeletedFilter(selected) {
      this.CustomFieldToSend = null;
      this.finalSelectClassifier = null;
      this.selectedCustomFieldsForFilter = null;
      this.nextFilter = null;
      if (selected.length === 0) {
        this.finalSelectClassifier = null;
      } else {
        let translated = [];
        if (selected.type === 1) {
          for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
            translated.push({
              name: translateTranslationTable(
                this.$store.state.locale.currentLocale,
                row.name
              ),
              key: row.key,
              type: 1,
            });
          }
        } else if (selected.type === 2) {
          this.customFieldToSend[selected.key] = "";
          translated = {
            type: 2,
            key: selected.key,
          };
        }
        this.nextFilter = translated;
      }
    },
    handleChanger() {
      this.fetchData(true);
    },
    // End of filter Methods
    async fetchCurrencies() {
      const response = await this.$store.$service[Service.BackendClient].get(
        "currencies",
        {
          params: {
            communityKey: this.$store.getters.currentCollective.key,
            count: 164,
          },
        }
      );
      this.currencyCode = response.data.data;
      for (let i = 0; i < this.currencyCode.length; i++) {
        this.currencyCo.push(this.currencyCode[i].ISO);
      }
      return response;
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData(force = false) {
      try {
              this.isLoading = true;

      await this.$store.dispatch("getItems", {
        itemType: "products",
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          count: 16,
          orderByDate: -1,
        }
      });
      } catch {

      } finally {
      this.isLoading = false;
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },

    async handleCreateItem(bvModalEvt) {
      // Name is required
      if (!this.productInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "products",
            requestConfig: {
              name: this.productInput.name,
              unitPrice: this.productInput.unitPrice,
              headline: this.productInput.headline,
              description: this.productInput.description,
              currencyCode: this.productInput.currencyCode,
              locations: this.productInput.locations,
            },
          },
          file: this.itemImage,
        });
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
        this.fetchData(true);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('success-message.general-success-create'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t("products.title"),
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
  },
};
</script>
<style scoped>
.titulo {
  font-size: 1.2em;
}
.precio {
  padding-top: 15px;
  font-size: 1.5em;
  font-weight: 900 !important;
}
.item-img {
  height: 200px !important;
}
.card-standard {
  height: 420px !important;
}
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}
</style>
